import React from "react";

import styled from '@emotion/styled';
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
// import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import home_hero_image from "../assets/images/home_hero_image.jpeg";

function HomePage() {
  return (
    <Layout>
      <SEO />
      <HomeSection className="Home__Section">
        <div className="bg:">
          <Headings.h1 className="Hero__Title">Home: This is homepage</Headings.h1>
        </div>
      </HomeSection>
    </Layout>
  );
}

export default HomePage;

const HomeSection = styled.section`
  height: 80vh;
  margin-top: 50px;
  background: url(${home_hero_image}), linear-gradient(180deg,#111216 0%,rgba(66,81,98,0.36) 100%);

  .Hero {
    text-align: center;
  }
  .Hero__Title {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`